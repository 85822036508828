<template>
	<div class="settlement">
		<router-link to="/AddressList?from=order" tag="div" class="top" v-if="hasAddress && address">
			<img class="address-img" src="@/assets/address.png" alt />
			<div class="mid">
				<p>{{ address.contacts }}<span>{{ address.contact_phone }}</span> </p>
				<p>{{ address.region + address.address }}</p>
			</div>
			<img class="right-img" src="@/assets/right.png" alt />
		</router-link>
		<router-link to="/AddressList?from=order" tag="div" class="top" v-else>
			<img class="address-img" src="@/assets/address.png" alt />
			<div class="mid">选择收货信息</div>
			<img class="right-img" src="@/assets/right.png" alt />
		</router-link>
		<div class="goods-list">
			<div class="goods-item">
				<img :src="goods.goods_imgurl" alt />
				<div class="goods-info">
					<p class="goods-name">{{ goods.goods_name }}</p>
					<div class="goods-spec">{{ goods.specs_name }}</div>
					<div class="flex-between">
						<div class="goods-price">
							<span>￥{{ goods.goods_price }}</span>
						</div>
						<div class="goods-num">×{{ goods.goods_nums }}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bot-info">
			<div class="row">
				<div>运费</div>
				<div style="color: #e40011">￥{{ detail.postage_amount }}</div>
			</div>
			<div class="row">
				<div>合计</div>
				<div style="color: #e40011">￥{{ total }}</div>
			</div>
			<div class="row">
				<div>支付方式</div>
				<div>通联支付</div>
			</div>
		</div>
		<div class="btn" @click="toPayPost">立即支付</div>
	</div>
</template>

<script>
	import {
		getGoodsPreparePay,
		payOrderGoods,
		payGoods
	} from "@/http/api";
	import {
		chooseWXPay
	} from "@/common/wxJssdk";
	export default {
		components: {},
		data() {
			return {
				userToken: "",
				id: 0,
				share_id: 0,
				referrer_id: 0,
				detail: {},
				goods: {},
				address: "",
				hasAddress: true,
				isPost: false,
				pay_types: 3
			};
		},
		mounted() {
			//  执行判断登录
			let route = this.$route.query;
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			if (route.id) {
				this.id = route.id;
				this.share_id = route.share_id ? route.share_id : 0;
				this.referrer_id = route.referrer_id ? route.referrer_id : 0;
				console.log(this.referrer_id)
				this.getGoodsPreparePay();
			} else {
				this.$dialog.alert({
					message: '订单预结算信息获取错误, 点击跳转到首页看看',
				}).then(() => {
					this.$router.replace({
						path: "/home"
					});
				});
			}
		},
		methods: {
			// 获取订单结算信息（实物）
			async getGoodsPreparePay() {
				const res = await getGoodsPreparePay({
					data: {
						user_token: this.userToken,
						buffer_id: this.id
					}
				});
				this.detail = res.data;
				this.goods = res.data.goods_lists;
				this.address = res.data.address_info;
				let address = this.$store.state.addressInfo;
				// console.log(address);
				if (address) {
					this.address = address;
				}
				if (this.address.length == 0) {
					this.hasAddress = false;
				}
			},
			// 点击支付按钮
			toPayPost() {
				if (this.address == "") {
					this.$toast("请选择收货地址");
					return;
				}
				if (this.isPost) {
					this.$toast("请勿重复提交");
					return;
				} else {
					this.toPayOrder();
				}
			},
			//提交支付
			async toPayOrder() {
				this.isPost = true;
				const res = await payOrderGoods({
					data: {
						user_token: this.userToken,
						site_code: this.$LStorage.getItem("curSiteInfo").site_code,
						buffer_id: this.id,
						pay_types: this.pay_types,
						referrer_id: this.referrer_id,
						share_id: this.share_id,
						address_contacts: this.address.contacts,
						address_phone: this.address.contact_phone,
						address_details: this.address.region + this.address.address
					}
				});
				console.log(res);
				if (res.code == 200) {
					this.toPay(res.data); //发起支付
					// this.payGoods(res.data); //模拟支付
				} else {
					this.$toast(res.msgs);
				}
			},
			// 发起支付
			async toPay(datas) {
				let that = this;
				if (that.pay_types == 1) {
					chooseWXPay(datas.payInfo, res => {
						if (res) {
							this.$router.replace({
								path: "/ShopMallPaySuccess"
							});
						} else {
							// that.closeOrder(datas)
						}
					});
				} else if (that.pay_types == 3) {
					window.location.href = datas.payInfo;
				}

			},
			// 执行模拟支付（实物）
			async payGoods(data) {
				this.isPost = true;
				const res = await payGoods({
					data: {
						user_token: this.userToken,
						order_id: data.payIds.order_id,
						referrer_id: this.referrer_id,
						total_fee: this.total
					}
				});
				this.isPost = false;
				if (res.code == "200") {
					this.$router.replace({
						path: "/ShopMallPaySuccess"
					});
				} else {
					this.$toast(res.msgs);
				}
			}
		},
		computed: {
			total() {
				if (this.detail) {
					let goodsP = Number(this.detail.goods_amount),
						postP = Number(this.detail.postage_amount),
						total = goodsP + postP;
					if (total) return total;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.settlement {
		* {
			box-sizing: border-box;
		}

		background: #f6f6f6;
		min-height: 100vh;
		padding-bottom: calc(0.84rem + 6vw);

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 3vw;
			height: 1.5rem;
			background: #fff;
			margin-bottom: 0.25rem;

			.address-img {
				width: 0.35rem;
			}

			.right-img {
				width: 0.25rem;
			}

			.mid {
				width: 80vw;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 0.28rem;

				p:first-child {
					margin-bottom: 5px;
				}

				span {
					font-size: 0.26rem;
					color: #8a8a8a;
					margin-left: 2em;
				}
			}
		}

		.goods-list {
			// padding: 0.2rem 3vw;
			margin: 0 3vw;
			margin-bottom: 0.2rem;

			.goods-item {
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.1rem;
				border-radius: 0.1rem;
				margin-bottom: 10px;

				img {
					width: 24vw;
					height: 24vw;
					border-radius: 5px;
					object-fit: cover;
				}

				.goods-info {
					width: 64vw;
					height: 24vw;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.goods-name {
						font-size: 0.28rem;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.goods-spec {
						background: #f5f5f5;
						border-radius: 5px;
						padding: 0.1rem 0.15rem;
						font-size: 0.24rem;
						color: #848484;
						max-width: calc(64vw - 0.5rem);
						width: max-content;
					}

					.goods-num {
						font-size: 0.24rem;
					}

					.goods-price {
						font-size: 0.24rem;
						color: #da1313;

						span {
							font-size: 0.3rem;
							font-weight: bold;
						}
					}
				}
			}
		}

		.bot-info {
			background: #fff;
			border-radius: 8px;
			overflow: hidden;
			width: 94vw;
			margin: 0 3vw;

			.row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 0.24rem;
				height: 1.2rem;
				font-size: 0.28rem;
				border-bottom: 1px solid #eee;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.btn {
			width: 90.8vw;
			height: 0.84rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			left: 4.1vw;
			bottom: 3vw;
			font-size: 0.3rem;
			color: #fff;
			background: #e50012;
			border-radius: 0.84rem;
		}

		.model1 {
			// display: none;
			background: rgba(0, 0, 0, 0.5);
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1001;

			.msg-box {
				position: absolute;
				top: 16vh;
				left: 8vw;
				width: 84vw;
				background: #fff;
				border-radius: 5px;

				.close-img {
					width: 0.65rem;
					height: 0.65rem;
					position: absolute;
					top: -0.3rem;
					right: -0.3rem;
				}

				.msg-title {
					background: #ff6521;
					color: #fff;
					font-size: 0.36rem;
					height: 0.9rem;
					width: 100%;
					text-align: center;
					line-height: 0.9rem;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
					margin-bottom: 0.55rem;
				}

				.msg {
					font-size: 0.28rem;
					text-align: center;
					margin-bottom: 0.45rem;
				}

				.msg-link {
					background: #e50112;
					color: #fff;
					font-size: 0.3rem;
					height: 0.75rem;
					width: 1.75rem;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;
					margin: 0 auto 0.55rem;
				}
			}
		}
	}
</style>
